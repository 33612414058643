import Case from "case";
import { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { Item } from "../interface";
import OptionItem from "../OptionItem";
import { ModifierProps } from "./interface";
import * as S from "./styles";

const Modifier = ({
  modifier,
  onChangeModifier,
  index,
  options,
}: ModifierProps) => {
  console.log(options);
  const [isOpen, setIsOpen] = useState(true);
  const [subtotal, setSubtotal] = useState<Item[]>(modifier.options);
  const [isMaxOfModifier, setIsMaxOfModifier] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const isRequired = modifier.min > 0;

  const getModifierType = () => {
    if (modifier.min === 1 && modifier.max === 1) return "radio";
    return "number";
  };

  const modifierType = getModifierType();

  const handleMaxOfModifier = () => {
    const totalOfModifier = subtotal.reduce((acc, item) => {
      return (acc += item.qty || 0);
    }, 0);

    setIsMaxOfModifier(totalOfModifier >= modifier.max);
  };

  useEffect(() => {
    handleMaxOfModifier();

    let newIsValid = true;
    if (isRequired) {
      const someOptionSelected = subtotal.some((item) => {
        return item.qty >= modifier.min;
      });
      newIsValid = someOptionSelected;
    }

    setIsValid(newIsValid);
    onChangeModifier(subtotal, index, newIsValid);
  }, [subtotal]);

  useEffect(() => {
    handleMaxOfModifier();

    let newIsValid = true;
    if (isRequired) {
      const someOptionSelected = subtotal.some((item) => {
        return item.qty >= modifier.min;
      });
      newIsValid = someOptionSelected;
    }

    setIsValid(newIsValid);
    onChangeModifier(subtotal, index, newIsValid);
  }, []);

  const onCollapse = () => {
    setIsOpen(!isOpen);
  };

  const onChangeOption = (option: Item, qty: number) => {
    const newSubtotal = subtotal.map((item) => {
      if (option.itemId === item.itemId) item.qty = qty;

      if (modifierType === "radio" && option.itemId !== item.itemId)
        item.qty = 0;
      return item;
    });
    setSubtotal(newSubtotal);
  };

  const listOfOptions = modifier.options.map((option: any) => (
    <OptionItem
      key={option.itemId}
      item={option}
      options={options}
      type={modifierType}
      modifier={modifier}
      onChange={onChangeOption}
      disableIncrease={isMaxOfModifier}
    />
  ));

  const getModifierDescription = () => {
    const onlyOneItem = modifier.min == 1 && modifier.max == 1;

    if (modifier.max > 1 && modifier.max < 99) {
      return `Choose up to ${modifier.max} options`;
    }

    if (onlyOneItem) return "Choose one of the options";

    return "Choose from the following options";
  };

  return (
    <S.Wrapper>
      <S.Header onClick={onCollapse}>
        <div>
          <S.Title>{Case.capital(Case.lower(modifier.name))}</S.Title>
          <S.Description>{getModifierDescription()}</S.Description>
        </div>
        <S.RightContent>
          {isRequired && <S.Tag>REQUIRED</S.Tag>}
          <S.ArrowIcon isOpen={isOpen} size="24px" />
        </S.RightContent>
      </S.Header>
      <Collapse isOpened={isOpen}>
        <S.Content>{listOfOptions}</S.Content>
      </Collapse>
    </S.Wrapper>
  );
};

export default Modifier;
