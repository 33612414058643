import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import ContentLoader from "react-content-loader";
import ReactTooltip from "react-tooltip";

import { AddressType } from "components/Cart/interface";
import Empty from "components/Empty";
import { Modal } from "components/Modal";
import { useAuth } from "contexts/AuthUserContext";
import api from "services/api";

import * as B from "components/ButtonStyle/styles";
import * as S from "./styles";

const MyLoader = () => (
  <ContentLoader
    height={110}
    speed={1}
    foregroundColor={"#e6e6e6"}
    viewBox="0 0 280 40"
  >
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
  </ContentLoader>
);

type AddressListProps = {
  items: AddressType[];
  cursor?: "pointer" | "default";
  onEditAddress: (address: AddressType | null) => void;
  handleOpenNewAddress?: Dispatch<SetStateAction<boolean>>;
  handleCloseAddresses?: Dispatch<SetStateAction<boolean>>;
  onSetAddress?: (address: AddressType | null) => void;
  onLoading?: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
};

const AddressList = ({
  items,
  cursor = "pointer",
  handleCloseAddresses,
  handleOpenNewAddress,
  onSetAddress,
  onEditAddress,
  onLoading,
  loading = false,
}: AddressListProps) => {
  const [addresses, setAddresses] = useState<AddressType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean[]>([]);
  const { authUser } = useAuth();

  const handleDeleteAddress = useCallback(async (address: AddressType) => {
    setIsLoading(true);
    onLoading && onLoading(true);
    try {
      const {
        data: { addresses },
      } = await api.delete(`/users/${authUser?.uid}/addresses`, {
        data: { address },
      });
      setAddresses(addresses);
      setIsOpenConfirm([...Array(addresses.length)].map(() => false));
      if (addresses.length === 0) {
        onSetAddress && onSetAddress(null);
      }
    } finally {
      setIsLoading(false);
      onLoading && onLoading(false);
    }
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      setAddresses(items);
      setIsOpenConfirm([...Array(items.length)].map(() => false));
      setIsLoading(false);
      onLoading && onLoading(false);
    }
  }, [items]);

  return (
    <S.Addresses>
      {isLoading || loading ? (
        <MyLoader />
      ) : addresses.length === 0 ? (
        <S.Box>
          <Empty
            image="/img/icons-message-empty-state.svg"
            description="You don’t have any registered address yet."
          />
        </S.Box>
      ) : (
        addresses.map((address, index) => (
          <S.Address key={`${address.streetName}-${index}`}>
            <S.WrapperButton
              onClick={() => {
                handleCloseAddresses && handleCloseAddresses(false);
                onSetAddress && onSetAddress(address);
              }}
              cursor={cursor}
            >
              <S.AddressImage>
                <img src="/img/pin-bag.svg" />{" "}
              </S.AddressImage>

              <S.AddressDescription>
                <h3>
                  {address.description}
                  {address.isDefault && <span>default</span>}
                </h3>
                <p>
                  {address.streetNumber} {address.streetName}
                </p>
                <p>{address.city}</p>
                <p>Extra Info: {address.extraInfo}</p>
              </S.AddressDescription>
            </S.WrapperButton>

            <S.WrapperActions>
              <S.DeleteButton
                onClick={() =>
                  setIsOpenConfirm((old) => [
                    ...old.map((item, i) => i === index),
                  ])
                }
                disabled={isLoading}
                data-tip="Delete"
              >
                <img src="/img/icon-delete.svg" />
              </S.DeleteButton>
              <S.EditButton
                onClick={() => {
                  onEditAddress(address);
                  handleOpenNewAddress && handleOpenNewAddress(true);
                }}
                disabled={isLoading}
                data-tip="Edit"
              >
                <img src="/img/icon-edit.svg" />
              </S.EditButton>
              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </S.WrapperActions>

            <Modal
              widthAuto
              open={isOpenConfirm[index]}
              onClose={() =>
                setIsOpenConfirm((old) => [...old.map((item, i) => false)])
              }
            >
              <S.ModalWrapper>
                <S.Container>
                  <S.ConfirmTitle>
                    Delete Address {address.streetName}
                  </S.ConfirmTitle>
                  <S.ConfirmDescription>
                    Do you have certain that you want to delete this address?
                  </S.ConfirmDescription>
                  <B.ButtonContainer>
                    <B.NegativeButton
                      onClick={() =>
                        setIsOpenConfirm((old) => [
                          ...old.map((item, i) => false),
                        ])
                      }
                    >
                      No
                    </B.NegativeButton>
                    <B.Button onClick={() => handleDeleteAddress(address)}>
                      Yes
                    </B.Button>
                  </B.ButtonContainer>
                </S.Container>
              </S.ModalWrapper>
            </Modal>
          </S.Address>
        ))
      )}
    </S.Addresses>
  );
};

export default AddressList;
