import { useEffect } from "react";
import ReactDOM from "react-dom";
import * as S from "./styles";

export type ModalProps = {
  open: boolean;
  title?: string;
  onClose: () => void;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  noHeader?: boolean;
  widthAuto?: boolean;
  style?: React.CSSProperties;
};

export const Modal = ({
  open,
  title = "",
  onClose,
  children,
  hideCloseButton = false,
  noHeader = false,
  widthAuto = false,
  style,
}: ModalProps) => {
  useEffect(() => {
    const hasModal = document.querySelector(".modal");
    if (open || hasModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  if (!open) return null;
  if (typeof document === 'undefined') return null;

  return ReactDOM.createPortal(
    <>
      <S.Overlay open={open} onClick={() => onClose()} />
      <S.Wrapper
        open={open}
        widthAuto={widthAuto}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
        className="modal"
      >
        <S.ModalWrapper style={style}>
          <S.Content>
            {!noHeader && (
              <S.Header>
                <S.Title>{title}</S.Title>

                {!hideCloseButton && (
                  <S.CloseButton
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => onClose()}
                  >
                    ✕
                  </S.CloseButton>
                )}
              </S.Header>
            )}

            {children}
          </S.Content>
        </S.ModalWrapper>
      </S.Wrapper>
    </>,
    document.getElementById("modal-root")!
  );
};

export const ModalOverflow = S.ModalOverflow;
export const ModalFooter = S.ModalFooter;
