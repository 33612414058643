import { useState } from "react";
import * as S from "../styles";

const moneyFormatter = (money: number) => "$" + (money / 100).toFixed(2);

const ItemPrice = ({ item }: any) => {
  const newPrice = item.prices?.length > 0 ? item.prices[0]?.price : 0;
  const [hasDiscountPrice] = useState(newPrice > 0 && newPrice < item.price);

  if (hasDiscountPrice)
    return (
      <S.ItemPrice>
        <S.ItemPriceDiscount>
          {moneyFormatter(item.prices[0]?.price)}
        </S.ItemPriceDiscount>
        <S.ItemPriceOverline>{moneyFormatter(item.price)}</S.ItemPriceOverline>
      </S.ItemPrice>
    );

  return item.price > 0 ? (
    <S.ItemPrice>{moneyFormatter(item.price)}</S.ItemPrice>
  ) : (
    <></>
  );
};

export default ItemPrice;
