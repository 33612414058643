import { WifiOff as WifiOffIcon } from "@styled-icons/material-outlined";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MessagingContainer from "components/Messaging";
import Snackbar from "components/Snackbar";
import { AuthUserProvider } from "contexts/AuthUserContext";
import { CartProvider } from "contexts/CartContext";
import GlobalStyles from "styles/global";
import { GoogleAnalytics } from "components/GoogleAnalytics";

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isOnline, setIsOnline] = useState(true);

  const checkOnlineStatus = useCallback(() => {
    setIsOnline(navigator.onLine);
  }, []);

  useEffect(() => {
    NProgress.configure({
      showSpinner: false,
    });
    const handleStart = (url: string) => {
      NProgress.start();
    };
    const handleStop = () => {
      NProgress.done();
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  const GTM_ID = process.env.NEXT_PUBLIC_MEASUREMENT_ID;

  useEffect(() => {
    if ("onLine" in navigator) {
      const intervalOnlineStatus = setInterval(checkOnlineStatus, 5000);

      return () => clearInterval(intervalOnlineStatus);
    }
  }, [checkOnlineStatus]);

  return (
    <AuthUserProvider>
      <CartProvider>
        <Head>
          <title>Bento - All your favorite food in one place</title>
          <link rel="preconnect" href="https://fonts.gstatic.com" />

          <link
            href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap"
            rel="stylesheet"
          />

          <link
            href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap"
            rel="stylesheet"
          />
          <link rel="shortcut icon" href="/img/favicon.png" />
          <link rel="apple-touch-icon" href="/img/favicon.png" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="theme-color" content="#ff4c4c" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width,minimum-scale=1, user-scalable=no"
          />
          <meta
            name="description"
            content="Bento - All your favorites in one place"
          />
        </Head>
        <GlobalStyles />
        <div className="mainwrapper">
          <Snackbar
            icon={<WifiOffIcon size={32} />}
            title="It seems that you're offline"
            message="Please check your connection and try again."
            open={!isOnline}
          />
          <GoogleAnalytics measureId={GTM_ID || ""} />
          <Component {...pageProps} />
          <ToastContainer />
          <MessagingContainer />
        </div>
      </CartProvider>
    </AuthUserProvider>
  );
}

export default App;
