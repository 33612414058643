import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32rem;
  width: 100%;
`;

export const VoucherList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  padding-top: 0.8rem;

  .tooltip {
    padding: 0.3rem 0.6rem;
    border-radius: 0.8rem;
    font-family: "Quicksand";
    font-size: 1.2rem;
  }
`;

type WrapperButtonProps = {
  cursor: "pointer" | "default";
  isCart: boolean;
  added?: boolean;
};

export const VoucherItem = styled.div<Pick<WrapperButtonProps, "isCart">>`
  ${({ isCart }) => css`
    width: 100%;
    @media (min-width: 768px) {
      width: ${isCart ? "100%" : "auto"};
    }
  `}
`;

export const WrapperButton = styled.button<WrapperButtonProps>`
  ${({ cursor, isCart, added = false }) => css`
    display: flex;
    gap: 16px;
    align-items: stretch;
    width: 100%;
    max-width: ${isCart ? "auto" : "100%"};

    cursor: ${cursor};

    border-radius: 16px;
    border: 1px solid #d5d5d6;
    background: #fff;

    @media (min-width: 768px) {
      width: ${isCart ? "100%" : "auto"};
    }

    ${added &&
    css`
      background-color: ${Theme.colors.wildSand};
    `}
  `}
`;

export const VoucherItemImage = styled.div<{ bg: string }>`
  width: 100px;
  height: 110px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #d8d8d8;
  background-image: url(${({ bg }) => bg});
  background-position: center center;
  background-size: cover;
`;

export const VoucherItemTitle = styled.div`
  font-family: "Quicksand";
  font-size: 1.2rem;
  font-weight: 700;
  color: #54545a;
  text-align: left;
`;

export const VoucherItemInfo = styled.div`
  font-family: "Quicksand";
  font-size: 1.2rem;
  color: ${Theme.colors.gray};
  text-align: left;
`;

export const VoucherItemExpireDate = styled.div`
  font-family: "Quicksand";
  font-size: 1rem;
  color: ${Theme.colors.gray};
  text-align: left;
  margin-top: 14px;
`;

export const VoucherInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px 16px 0;
  heigth: 100%;
`;
