import { GoogleMap, LoadScriptNext } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { AddressType } from "components/Cart/interface";
import Radio from "components/Radio";
import Switch from "components/Switch";
import TextField from "components/TextField";
import { useAuth } from "contexts/AuthUserContext";
import useCart from "hooks/useCart";
import api from "services/api";
import Alert from "../Alert";

import * as C from "../../styles";
import * as S from "./styles";

const stepMap = {
  SEARCH: 1,
  MAP: 2,
  FORM: 3,
};

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  mapTypeId: "hybrid",
};

const DropdownIndicator = (props: any) => {
  return (
    <div {...props}>
      <img src="/img/icons-others-search.svg" />
    </div>
  );
};

enum TypeAddressEnum {
  APARTMENT = "apartment",
  HOUSE = "house",
}

type LatLngType = {
  lat: number;
  lng: number;
};

type FormAddressProps = {
  closeNewAddressForm: () => void;
  onSuccess: (address: any) => void;
  oldAddress?: AddressType | null;
  isOpen: boolean;
  isBentoPackage: boolean;
};

const FormAddress = ({
  closeNewAddressForm,
  onSuccess,
  oldAddress = null,
  isOpen,
  isBentoPackage,
}: FormAddressProps) => {
  const [streetNumber, setStreetNumber] = useState("");
  const [extraInfo, setExtraInfo] = useState("");
  const [instructions, setInstructions] = useState("");
  const [typeAddress, setTypeAddress] = useState("");
  const [step, setStep] = useState(stepMap.SEARCH);
  const [isLoading, setIsLoading] = useState(false);
  const [inQuarantine, setInQuarantine] = useState(false);
  const [isDefault, setIsDefault] = useState(oldAddress?.isDefault);
  const [address, setAddress] = useState<AddressType | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapCenter, setMapCenter] = useState<LatLngType>({
    lat: 19.287408635276158,
    lng: -81.36642566177018,
  });
  const [filterText, setFilterText] = useState("");
  const { authUser } = useAuth();
  const { setAddresses } = useCart();

  const clearForm = useCallback(() => {
    setStreetNumber("");
    setTypeAddress("");
    setExtraInfo("");
    setInstructions("");
    setInQuarantine(false);
  }, []);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    const quarantineText = " • 😷 IN QUARANTINE 😷";
    const newAddress = {
      ...address,
      extraInfo: `${
        typeAddress === TypeAddressEnum.APARTMENT ? "Apt • " : "House • "
      }${extraInfo}`,
      instructions: `${instructions}${inQuarantine ? quarantineText : ""}`,
      streetNumber,
      isDefault: isDefault,
    } as AddressType;

    const dataSave: { newAddress: AddressType; oldAddress?: AddressType } = {
      newAddress,
    };
    if (oldAddress) {
      dataSave.oldAddress = oldAddress;
    }

    try {
      const { data } = await api.post(
        `/users/${authUser?.uid}/addresses`,
        dataSave
      );
      clearForm();
      setAddresses(data.addresses);
      onSuccess(dataSave.newAddress);
      setStep(stepMap.SEARCH);
    } finally {
      setIsLoading(false);
    }
  }, [
    address,
    instructions,
    inQuarantine,
    streetNumber,
    typeAddress,
    extraInfo,
    oldAddress,
  ]);

  const handlePlaceChange = useCallback(
    async (e: any) => {
      setFilterText(e);
      const { value } = e;

      var request = {
        placeId: value.place_id,
        fields: ["name", "address_component", "formatted_address", "geometry"],
      };

      const service = new google.maps.places.PlacesService(map!);
      service.getDetails(request, callback);

      function callback(
        place: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          const { address_components, geometry } = place!;

          let newAddress: AddressType = {} as AddressType;

          if (address_components && address_components?.length > 0) {
            address_components.forEach((item) => {
              const { long_name, short_name, types } = item;
              newAddress.streetName = types.includes("route")
                ? long_name
                : newAddress.streetName;
              newAddress.streetNumber = types.includes("street_number")
                ? short_name
                : newAddress.streetNumber;
              newAddress.city = types.includes("locality")
                ? long_name
                : newAddress.city;
              newAddress.country = types.includes("country")
                ? long_name
                : newAddress.country;
            });
          }

          const coordinates = {
            lat: geometry?.location?.lat() as number,
            lng: geometry?.location?.lng() as number,
          };

          setAddress({
            ...newAddress,
            description: place?.name || "",
            coordinates,
          });
          setStreetNumber(newAddress.streetNumber);

          setMapCenter(coordinates);
          setFilterText("");
          setStep(stepMap.MAP);
        }
      }
    },
    [map]
  );

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const handleDragMap = useCallback(() => {
    const center = map?.getCenter() as any;
    if (center) {
      setMapCenter({ lat: center.lat(), lng: center.lng() });
    }
  }, [map]);

  const handleConfirmMap = useCallback(async () => {
    setAddress(
      (old) => ({ ...old, coordinatesAdjustment: mapCenter } as AddressType)
    );
    setStep(stepMap.FORM);
  }, [mapCenter]);

  const handleCloseOrBack = useCallback(async () => {
    if (step === stepMap.SEARCH) {
      closeNewAddressForm();
    } else if (step === stepMap.MAP && !oldAddress) {
      setStep(stepMap.SEARCH);
    } else if (step === stepMap.MAP && oldAddress) {
      closeNewAddressForm();
    } else {
      setStep(stepMap.MAP);
    }
  }, [step]);

  useEffect(() => {
    clearForm();

    if (oldAddress) {
      setAddress(oldAddress);
      setMapCenter({
        lat: oldAddress.coordinatesAdjustment.lat || 0,
        lng: oldAddress.coordinatesAdjustment.lng || 0,
      });
      setStreetNumber(oldAddress.streetNumber);
      setTypeAddress(
        oldAddress.extraInfo.indexOf("Apt • ") > -1
          ? TypeAddressEnum.APARTMENT
          : TypeAddressEnum.HOUSE
      );
      setExtraInfo(
        oldAddress.extraInfo.replace("Apt • ", "").replace("House • ", "")
      );
      setInQuarantine(
        oldAddress.instructions.indexOf(" • 😷 IN QUARANTINE 😷") > -1
      );
      setInstructions(
        oldAddress.instructions.replace(" • 😷 IN QUARANTINE 😷", "")
      );
      setStep(stepMap.MAP);
    } else {
      setStep(stepMap.SEARCH);
    }
  }, [oldAddress]);

  useEffect(() => {
    if (isOpen) {
      window.document.getElementById("react-select-2-input")?.focus();
    }
  }, [isOpen]);

  const isAllowToSubmit = () => {
    return !(
      !isLoading &&
      typeAddress.length > 0 &&
      Boolean(streetNumber) &&
      Boolean(extraInfo) &&
      Boolean(instructions)
    );
  };

  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.NEXT_PUBLIC_MAPS_API_KEY!}
      libraries={["places"]}
    >
      <C.CartContent noPadding={step === stepMap.MAP}>
        <C.MobileMerchantHeader>
          <h2>New address</h2>

          <C.MobileCloseButton onClick={closeNewAddressForm}>
            <img src="/img/icons-close.svg" />
          </C.MobileCloseButton>
        </C.MobileMerchantHeader>

        <S.BackStepButton onClick={handleCloseOrBack}>
          <img src="/img/icons-left.svg" style={{ marginRight: "3px" }} />
        </S.BackStepButton>

        <GoogleMap
          options={options}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            display: step === stepMap.MAP ? "block" : "none",
          }}
          zoom={18}
          center={mapCenter}
          clickableIcons={false}
          onLoad={onLoad}
          onDragEnd={handleDragMap}
        >
          <S.AlertWrapper>
            <Alert
              title="Roof adjustment"
              description="Please adjust your location. You probably know how your roof looks"
              style={{ width: "100%" }}
            />
          </S.AlertWrapper>

          <S.Marker src="/img/pin-map.svg" />

          <S.ButtonWrapper
            style={{
              width: "100%",
              position: "absolute",
              bottom: "2.4rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <S.ButtonConfirmMap onClick={handleConfirmMap}>
              Confirm
            </S.ButtonConfirmMap>
          </S.ButtonWrapper>
        </GoogleMap>

        {step !== stepMap.MAP && (
          <C.OverflowContent>
            <C.Section noBorderBottom>
              <S.NewAddressTitle>New address</S.NewAddressTitle>

              {step === stepMap.SEARCH && (
                <S.FormAddress>
                  <S.FormRow style={{ marginBottom: "2.4rem" }}>
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: { country: ["ky"] },
                      }}
                      debounce={500}
                      selectProps={{
                        value: filterText,
                        onChange: handlePlaceChange,
                        placeholder: "Address",
                        noOptionsMessage: () => (
                          <S.TextHelp>
                            <h4>Can’t find your address?</h4>
                            <p>
                              Try searching by a closer location and adjust the
                              coordinates on next step
                            </p>
                          </S.TextHelp>
                        ),
                        loadingMessage: () => "Searching...",
                        components: {
                          DropdownIndicator,
                          IndicatorSeparator: null,
                        },
                        openMenuOnFocus: true,
                        theme: (theme: any) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#c5c0c0",
                          },
                        }),
                        styles: {
                          input: (provided: any) => ({
                            ...provided,
                            color: "#8e8b8b",
                            fontSize: "1.2rem",
                          }),
                          control: (provided: any) => ({
                            ...provided,
                            borderRadius: "1.3rem",
                            border: "0.1rem solid #c5c0c0",
                          }),
                          placeholder: (provided: any) => ({
                            ...provided,
                            color: "#8e8b8b",
                            fontSize: "1.2rem",
                          }),
                          singleValue: (provided: any) => ({
                            ...provided,
                            color: "#8e8b8b",
                            fontSize: "1.2rem",
                          }),
                        },
                      }}
                    />
                  </S.FormRow>
                </S.FormAddress>
              )}

              {step === stepMap.FORM && (
                <>
                  <S.Address>
                    <S.AddressImage>
                      <img src="/img/pin-bag.svg" />{" "}
                    </S.AddressImage>

                    <S.AddressDescription>
                      <h3>{address?.description}</h3>
                      <p>
                        {address?.city ? `${address?.city}, ` : ""}
                        {address?.country}
                      </p>
                    </S.AddressDescription>
                  </S.Address>

                  <S.FormAddress>
                    <S.FormRow>
                      <S.RadioInline>
                        <Radio
                          label="Apartment"
                          labelFor="apartment"
                          id="apartment"
                          name="type_address"
                          value={TypeAddressEnum.APARTMENT}
                          onCheck={() =>
                            setTypeAddress(TypeAddressEnum.APARTMENT)
                          }
                          defaultChecked={
                            typeAddress === TypeAddressEnum.APARTMENT
                          }
                          disabled={isLoading}
                        />
                        <Radio
                          label="House"
                          labelFor="house"
                          id="house"
                          name="type_address"
                          value={TypeAddressEnum.HOUSE}
                          onCheck={() => setTypeAddress(TypeAddressEnum.HOUSE)}
                          defaultChecked={typeAddress === TypeAddressEnum.HOUSE}
                          disabled={isLoading}
                        />
                      </S.RadioInline>
                    </S.FormRow>
                    <S.FormRow>
                      <TextField
                        isRequired
                        variant="material"
                        type="text"
                        name="streetNumber"
                        label="Street Number"
                        labelFor="streetNumber"
                        id="streetNumber"
                        value={streetNumber}
                        onInput={(v) => setStreetNumber(String(v))}
                      />
                    </S.FormRow>
                    <S.FormRow>
                      <TextField
                        isRequired
                        variant="material"
                        type="text"
                        name="extraInfo"
                        label="Apt Number/Unit"
                        labelFor="extraInfo"
                        id="extraInfo"
                        value={extraInfo}
                        onInput={(v) => setExtraInfo(v as string)}
                      />
                    </S.FormRow>
                    <S.FormRow>
                      <TextField
                        isRequired
                        variant="material"
                        type="text"
                        name="instructions"
                        label="Instructions"
                        labelFor="instructions"
                        id="instructions"
                        placeholder="complex name, office, building color, etc"
                        value={instructions}
                        onInput={(v) => setInstructions(v as string)}
                      />
                    </S.FormRow>
                    <S.FormRow>
                      <S.Quarantine>
                        <S.QuarantineDescription>
                          <h3>Are you in quarantine?</h3>
                          <p>We will provide contactless delivery if so</p>
                        </S.QuarantineDescription>

                        <Switch
                          checked={inQuarantine}
                          id="inQuarantine"
                          onChange={setInQuarantine}
                          disabled={isLoading}
                        />
                      </S.Quarantine>
                    </S.FormRow>
                    {!isBentoPackage && (
                      <S.FormRow>
                        <S.Quarantine>
                          <S.QuarantineDescription>
                            <h3>Set as default</h3>
                          </S.QuarantineDescription>

                          <Switch
                            checked={isDefault}
                            id="isDefault"
                            onChange={setIsDefault}
                            disabled={isLoading}
                          />
                        </S.Quarantine>
                      </S.FormRow>
                    )}
                  </S.FormAddress>
                </>
              )}
            </C.Section>
          </C.OverflowContent>
        )}

        {step === stepMap.FORM && (
          <C.ButtonWrapper>
            <S.ButtonSave
              disabled={isAllowToSubmit()}
              onClick={handleSave}
              loading={isLoading}
            >
              Save
            </S.ButtonSave>
          </C.ButtonWrapper>
        )}
      </C.CartContent>
    </LoadScriptNext>
  );
};

export default FormAddress;
