import Case from "case";

import InputNumber from "components/InputNumber";
import Radio from "components/Radio";
import { Tag } from "../Modifier/styles";
import { OptionItemProps } from "./interface";

import * as S from "./styles";

const moneyFormatter = (money: number) => "+ $" + (money / 100).toFixed(2);

const OptionItem = ({
  item,
  type,
  onChange,
  options,
  modifier,
  disableIncrease,
}: OptionItemProps) => {
  const onChangeItem = (qty: number) => {
    if (!getOptionStatus()) return;
    onChange(item, qty);
  };

  const getOptionName = () => {
    if (item.name) return item.name;

    const option = options?.filter((option) => {
      return item.itemId === option.itemId;
    });

    if (option) return option[0].name;

    return "";
  };

  const getOptionStatus = () => {
    const option = options?.filter((option) => {
      return item.itemId === option.itemId;
    });

    if (option) return option[0].available;

    return item.available;
  };

  const onSelectRadio = (value: any) => {
    const qty = 1;
    onChange(item, qty);
  };

  const onClickOption = () => {
    if (!getOptionStatus()) return;

    if (type === "radio") {
      onSelectRadio(1);
    }
  };

  return (
    <S.Wrapper onClick={onClickOption}>
      <S.ItemInfo>
        <S.ItemName>{Case.capital(Case.lower(getOptionName()))}</S.ItemName>
        {item.price > 0 && (
          <S.ItemPrice>{moneyFormatter(item.price)}</S.ItemPrice>
        )}
      </S.ItemInfo>
      {!getOptionStatus() && <Tag>SOLD OUT</Tag>}
      {type === "radio" && getOptionStatus() && (
        <Radio
          onCheck={onSelectRadio}
          checked={item.qty === 1}
          value={item.itemId}
          name={modifier.modifierId}
        />
      )}
      {type === "number" && getOptionStatus() && (
        <InputNumber
          onChangeValue={onChangeItem}
          maxValue={modifier.maxEachOption}
          disableIncrease={disableIncrease}
          value={item.qty || 0}
        />
      )}
    </S.Wrapper>
  );
};

export default OptionItem;
