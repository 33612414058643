import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import ContentLoader from "react-content-loader";
import ReactTooltip from "react-tooltip";

import Empty from "components/Empty";
import { Modal } from "components/Modal";
import { useAuth } from "contexts/AuthUserContext";
import api from "services/api";
import { CardType } from "./interface";

import * as B from "components/ButtonStyle/styles";
import * as S from "./styles";

const MyLoader = () => (
  <ContentLoader
    height={110}
    speed={1}
    foregroundColor={"#e6e6e6"}
    viewBox="0 0 280 40"
  >
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
  </ContentLoader>
);

type CardListProps = {
  items: CardType[];
  cursor?: "pointer" | "default";
  handleCloseCards?: Dispatch<SetStateAction<boolean>>;
  onSetPayment?: (card: CardType | null) => void;
  onLoading?: Dispatch<SetStateAction<boolean>>;
};

const CardList = ({
  items,
  cursor = "pointer",
  handleCloseCards,
  onSetPayment,
  onLoading,
}: CardListProps) => {
  const [cards, setCards] = useState<CardType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean[]>([]);
  const { authUser } = useAuth();

  const handleDeleteCard = useCallback(
    async (card: CardType) => {
      if (authUser?.uid) {
        setIsLoading(true);
        onLoading && onLoading(true);
        try {
          const { data } = await api.delete(
            `/users/${authUser?.uid}/payments`,
            {
              data: card,
            }
          );
          setCards(data);
          setIsOpenConfirm([...Array(data.length)].map(() => false));
          if (data.length === 0) {
            onSetPayment && onSetPayment(null);
          }
        } finally {
          setIsLoading(false);
          onLoading && onLoading(false);
        }
      }
    },
    [authUser]
  );

  useEffect(() => {
    if (items.length > 0) {
      setCards(items);
      setIsOpenConfirm([...Array(items.length)].map(() => false));
    }
  }, [items]);

  return (
    <S.Cards>
      {isLoading ? (
        <MyLoader />
      ) : cards.length === 0 ? (
        <S.Box>
          <Empty
            image="/img/icons-message-empty-state.svg"
            description="You don’t have any registered payment methods yet."
          />
        </S.Box>
      ) : (
        cards.map((card, index) => (
          <S.Card key={card.vaultId}>
            <S.WrapperButton
              cursor={cursor}
              onClick={() => {
                handleCloseCards && handleCloseCards(false);
                onSetPayment && onSetPayment(card);
              }}
            >
              <S.CardImage>
                <img src="/img/icons-card.svg" />{" "}
                {card.defaultMethod && <p>default</p>}
              </S.CardImage>

              <S.CardNumber>
                <p>••• {card.cardNumberEnd}</p>
              </S.CardNumber>
            </S.WrapperButton>

            <S.DeleteButton
              onClick={() =>
                setIsOpenConfirm((old) => [
                  ...old.map((item, i) => i === index),
                ])
              }
              disabled={isLoading}
              data-tip="Delete"
            >
              <img src="/img/icon-delete.svg" />
            </S.DeleteButton>
            <ReactTooltip
              effect="solid"
              className="tooltip"
              backgroundColor="#433d3d"
              borderColor="#433d3d"
            />

            <Modal
              widthAuto
              open={isOpenConfirm[index]}
              onClose={() =>
                setIsOpenConfirm((old) => [...old.map((item, i) => false)])
              }
            >
              <S.ModalWrapper>
                <S.Container>
                  <S.ConfirmTitle>
                    Delete Card •••{card.cardNumberEnd}
                  </S.ConfirmTitle>
                  <S.ConfirmDescription>
                    Do you have certain that you want to delete this card?
                  </S.ConfirmDescription>
                  <B.ButtonContainer>
                    <B.NegativeButton
                      onClick={() =>
                        setIsOpenConfirm((old) => [
                          ...old.map((item, i) => false),
                        ])
                      }
                    >
                      No
                    </B.NegativeButton>
                    <B.Button onClick={() => handleDeleteCard(card)}>
                      Yes
                    </B.Button>
                  </B.ButtonContainer>
                </S.Container>
              </S.ModalWrapper>
            </Modal>
          </S.Card>
        ))
      )}
    </S.Cards>
  );
};

export default CardList;
